import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { Modal } from "react-bootstrap";

import TextInputField from "../common/TextInputField";

import Notify from "../../../utils/Notify";
import Backend from "../../../utils/Backend";

const OnboardingValidationSchema = Yup.object({
  first_name: Yup.string().trim().required("First name is required"),
  last_name: Yup.string().trim().required("Last name is required"),
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required")
    .matches(/@[^.]*\./, "Invalid email address"),
});

const StartOnboarding = (props) => {
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({
    resolver: yupResolver(OnboardingValidationSchema),
  });
  const [loading, setLoading] = useState(false);

  const handleSendClick = (companyObject) => {
    setLoading(true);
    handleBackend(companyObject)
      .then((response) => {
        setLoading(false);
        Notify.success("Onboarding email has been sent.");
        props.onSuccess();
      })
      .catch((e) => {
        setLoading(false);
        Notify.error(e.message);
      });
  };

  const handleBackend = (companyObject) => {
    return Backend.updateCompany({...companyObject, id: props?.companyId});
  };

  return (
    <Modal
      show={props.show}
      onHide={() => props.onHide()}
      className={""}
      dialogClassName={"modal-dialog modal-dialog-centered"}
    >
      <Modal.Header closeButton>
        <h2 className="w-100">Start Onboarding</h2>
      </Modal.Header>

      <Modal.Body>
        <div class="alert alert-warning" role="alert">
          Fill in the details below for this company in order to send email for
          them to onboard to the platform.
        </div>
        <form onSubmit={handleSubmit(handleSendClick)}>
          <TextInputField
            isRequired
            label={"First Name"}
            outerContainerClassName="flex-column"
            errorMsg={errors.first_name?.message}
            placeholder={"First Name"}
            {...register("first_name")}
          />
          <TextInputField
            isRequired
            label={"Last Name"}
            outerContainerClassName="flex-column mt-5"
            errorMsg={errors.last_name?.message}
            placeholder={"Last Name"}
            {...register("last_name")}
          />
          <TextInputField
            isRequired
            label={"Email"}
            outerContainerClassName="flex-column mt-5"
            placeholder={"Email"}
            type={"email"}
            errorMsg={errors.email?.message}
            {...register("email")}
          />
          <div className="justify-content-end d-flex mt-5">
            <a className="btn btn-light me-3" onClick={() => props.onHide()}>
              Cancel
            </a>
            <button
              type="submit"
              className="btn btn-primary ml-3"
              disabled={loading}
            >
              Send
            </button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default StartOnboarding;
